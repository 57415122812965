<template>
  <div>
    <div class="my-6">
      <h3 class="font-bold">Editar produto</h3>
      <span class="text-sm text-gray-500">Verifique as informações abaixo e clique no botão <b>Salvar</b> para
        confirmar as modificações feitas
      </span>
    </div>
    <!-- Card-->
    <div class="card shadow-sm border p-8 bg-white">
      <div class="flex lg:flex-row flex-col">

        <div class="flex  flex-col">

          <div class="flex lg:flex-row flex-col p-1 ">
            <div class="w-full p-2">
              <Input type="datalist" id="evento" v-model="data.evento" label="Evento" placeholder="Selecione um evento"
                :data="eventos" />
            </div>
            <div class="w-full p-2">
              <Input type="datalist" id="tipo" v-model="data.tipo_produto" label="Tipo de produto"
                placeholder="Selecione um tipo" :data="tipos" />
            </div>
          </div>

          <div class="flex lg:flex-row flex-col p-1">
            <div class="w-full p-2">
              <Input v-if="!data.tipo_produto" type="datalist" id="subtipo" :readonly="true"
                v-model="data.subtipo_produto" label="Subtipo de produto" placeholder="Selecione um subtipo" />
              <Input v-else-if="subtipos.length == 0" type="datalist" id="subtipo" :readonly="true"
                v-model="data.subtipo_produto" label="Subtipo de produto" placeholder="Selecione um subtipo"
                :data="subtipos" />
              <Input v-else type="datalist" id="subtipo" v-model="data.subtipo_produto" label="Subtipo de produto"
                placeholder="Selecione um subtipo" :data="subtipos" />
            </div>
          </div>
          <!-- SKUS -->
          <div class="flex lg:flex-row flex-col p-1">
            <div class="w-full p-2">
              <Input type="default" v-model="data.codigo_produto" label="Código" placeholder="Código do produto" />
            </div>
            <div class="w-full p-2">
              <Input type="default" v-model="data.sku_magento" label="Código do Magento"
                placeholder="Código do Magento" />
            </div>
          </div>
          <div class="flex lg:flex-row flex-col p-1">
            <div class="w-full p-2">
              <Input type="money" v-model="tempvalor" label="Valor" placeholder="Valor" />
            </div>
            <div class="w-full p-2">
              <Input type="number" v-model="data.qnt_por_produto" label="Quantidade"
                placeholder="Quantidade do produto" />
            </div>

          </div>

          <div class="flex lg:flex-row flex-col p-1">
            <div class="w-full p-2">
              <Input type="select" v-model="data.sexo" label="Sexo" placeholder="Selecione um tipo" :data="[
                { label: 'M', value: 'M' },
                { label: 'F', value: 'F' },
                { label: 'I', value: 'I' },
              ]" />
            </div>
            <div class="w-full p-2">
              <Input type="number" v-model="data.lote" label="Lote" placeholder="Lote" />
            </div>
            <div class="w-full p-2">
              <Input type="tax" v-model="data.tx_conveniencia" label="Taxa de Conveniência"
                placeholder="Taxa de Conveniência" />
            </div>
          </div>

          <div class="flex p-1 m-2  h-20">
            <div class="w-full p-2">
              <Input type="textarea" v-model="data.detalhes" label="Detalhes" placeholder="Detalhes do produto" />
            </div>
          </div>

          <div class="flex lg:flex-row flex-col w-full p-1 m-2 mt-5">
            <div class="w-full p-2 lg:max-w-sm xl:max-w-lg">
              <label for="regras" class="block relative">
                <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm">
                  Regras do Produto
                </span>
                <QuillEditor theme="snow" :toolbar="toolbarOptions" :options="editorOptions"
                  v-model:content="data.regras_produto" id="regras" style="height: 200px;" class=""
                  contentType="html" />
              </label>
            </div>
          </div>
        </div>
        <!-- Imagem-->
        <div class="flex flex-col  md:w-full lg:w-2/5">
          <div class="block p-1 m-2  h-20 md:w-5/5">
            <Input type="datalist" v-model="data.pdv" label="PDV" placeholder="Selecione um PDV" :data="pdv" id="pdv" />
          </div>
          <div class="block p-1 m-2  h-20 w-5/5">
            <Input type="datalist" v-model="data.id_lote" label="Lotes" placeholder="Selecione um Lote" :data="lotes"
              id="Lote" />
          </div>

          <div class="block p-1 m-2  h-20 w-5/5">
            <Input type="datalist" v-model="data.variacao" label="Variações" placeholder="Selecione uma Variação"
              :data="variacoes" id="Variacão" />
          </div>


          <div class="block p-1 m-2  h-20 w-5/5">
            <Input type="datalist" v-model="data.dia" label="Dias" placeholder="Selecione um Dia" :data="dias"
              id="Dia" />
          </div>


          <div class="block p-1 m-2  h-20 w-5/5">
            <Input type="datalist" v-model="data.setor" label="Setores" placeholder="Selecione um Setor" :data="setores"
              id="Setor" />
          </div>


          <div class="flex flex-col mt-1 mx-3 gap-3 items-center">
            <img class="flex-auto rounded-lg shadow-lg w-36 h-36" :src="tempFile ? readURL(tempFile) : 'https://via.placeholder.com/700x300'
              " />
            <button class="
                  bg-primary
                  rounded-md
                  shadow-md
                  transform
                  hover:scale-105
                  px-5
                  py-2
                  static
                  flex
                  justify-center
                  items-center
                  font-bold
                " style="bottom: 20px; left: 20px">
              <label class="text-white" for="logo">Adicionar</label>
              <i class="ml-3 text-white gg-camera"></i>
              <input @change="changeImage" class="absolute opacity-0 bton" id="logo" type="file"
                style=" left: -1px; bottom: -35px;" />
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row lg:justify-start justify-center lg:mt-1 mt-10">
        <div class="lg:w-80 w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" :name="'dataEvento'" id="data_evento"
            v-model="data.inicio_validacao" label="Inicio Validação" />
        </div>
        <div class="lg:w-80 w-full p-2">
          <Input type="customDate" :placeholder="'Dia/Mês/Ano'" :name="'dataEvento'" id="data_evento"
            v-model="data.final_validacao" label="Fim Validação" />
        </div>
      </div>

      <div class="flex flex-col lg:flex-row lg:justify-start justify-center lg:mt-1 mt-10">
        <div class="lg:w-80 w-full p-2">
          <Input v-model="data.hora_inicio_validacao" id="Inicio" type="customTime" label="Hora Inicio Validação"
            placeholder="Hora Inicio Validação" :name="'horaInicioValidacao'" />
        </div>
        <div class="lg:w-80 w-full p-2">
          <Input v-model="data.hora_final_validacao" id="Inicio" type="customTime" label="Hora Fim Validação"
            placeholder="Hora Fim Validação" :name="'horaFimValidacao'" />
        </div>
      </div>

      <div class="flex p-1 m-2  h-20">
        <div class="w-full p-2">
          <label>
            <input type="checkbox" v-model="data.promoter" class="mr-2" />
            Criar produto para promoter
          </label>
        </div>
      </div>

      <!-- Botao-->
      <div class="flex justify-end mt-5">
        <Button @click="edit" color="primary" text="Salvar" />
      </div>
    </div>
    <!-- Envio de Ingresso fisico -->
    <div class="card shadow-sm border p-8 bg-white" v-if="isFisico">
      <div class="my-6">
        <h3 class="font-bold">Envio de Ingresso Fisico</h3>
        <span class="text-sm text-gray-500">Insira um arquivo com os Ingresso Fisicos a serem incluidos e clique no
          botão
          <b>Enviar</b> para
          confirmar as modificações feitas
        </span>
      </div>
      <h4> {{ IngressoFile.name }}</h4>
      <div>

        <button class="
              bg-primary
              rounded-md
              shadow-md
              transform
              hover:scale-105
              px-10
              w-full
              py-2
              flex
              justify-center
              items-center
              font-bold
            ">
          <label class="text-white" for="IngressoFisico">Adicionar Arquivo </label>
          <i class="ml-3 text-white gg-file"></i>
          <input @change="addFile" class="absolute hidden" id="IngressoFisico" type="file" accept=".xlsx" />
        </button>
      </div>

      <div class="flex justify-end mt-5">
        <Button @click="funcao" color="primary" text="Enviar" />
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PUT, POST } from "../../services/api";
import { toDate } from "../../services/formater";
import { inject, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "../../services/validate";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { dataAtualFormatada, dateBrtoStamp, dateWithTimeBrtoStamp, convertToUTC, formatDate, formatDateEnvio, convertDateToDBDefault } from "@/services/formater";


export default {
  name: "EditarProduto",
  components: {
    Input,
    Button,
    QuillEditor
  },
  data() {
    return {
      isMobile: false,
      toolbarOptions: [
        ['bold', 'italic', 'underline'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ],
      editorOptions: {
        modules: {
          autoformat: {
            remove: false,
            rules: [
              { newline: true, format: [] }
            ]
          }
        }
      },
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal")
    const data = ref({});
    const eventos = ref([]);
    const tipos = ref([]);
    const subtipos = ref([]);
    const tempvalor = ref("");
    const tempFile = ref("");
    const response = ref();
    const IngressoFile = ref("");
    const isFisico = ref("");

    // Novos Campos

    const dias = ref([]);
    const pdv = ref([]);
    const lotes = ref([]);
    const variacoes = ref([]);
    const setores = ref([]);

    const onMouted = ref(false)


    const subtiposRes = ref([]);

    const formataDataCorreta = (dateString) => {
      const date = new Date(dateString);

      const timeZoneOffset = -180;

      const localTime = new Date(date.getTime() + timeZoneOffset * 60 * 1000);

      const year = localTime.getUTCFullYear();
      const month = (localTime.getUTCMonth() + 1).toString().padStart(2, '0');
      const day = localTime.getUTCDate().toString().padStart(2, '0');
      const hours = localTime.getUTCHours().toString().padStart(2, '0');
      const minutes = localTime.getUTCMinutes().toString().padStart(2, '0');

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    onMounted(async () => {

      try {
        loader.open();
        const obj = { ...(await GET(`produto/${route.params.id}`)) };

        isFisico.value = false

        const config = await GET('produto/config')

        subtiposRes.value = await GET(`subtipo_produto`);

        for (const tipo of config.data) {
          switch (tipo.nome) {
            case 'Dias':
              dias.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Lotes':
              lotes.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Pdv':
              pdv.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Variacoes':
              variacoes.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
            case 'Setores':
              setores.value = tipo.data.map(el => {
                return {
                  label: el.nome,
                  value: el.id
                }
              })
              break;
          }
        }

        let [datePartInicio, timePartInicio] = ''
        let [datePartFinal, timePartFinal] = ''

        if (obj.inicio_validacao !== null && obj.final_validacao !== null) {
          [datePartInicio, timePartInicio] = formataDataCorreta(obj.inicio_validacao).split('T');
          [datePartFinal, timePartFinal] = formataDataCorreta(obj.final_validacao).split('T');

          data.value = {
            ...obj,
            "inicio_validacao": datePartInicio,
            "final_validacao": datePartFinal,
            "hora_inicio_validacao": timePartInicio.split('.')[0],
            "hora_final_validacao": timePartFinal.split('.')[0],
            evento: obj["id_evento"],
            "tipo_produto": obj["id_tipo_produto"],
            "subtipo_produto": obj["id_subtipo_produto"],
            "codigo_produto": obj["cod_produto"],
          }
        } else {

          data.value = {
            ...obj,
            "inicio_validacao": null,
            "final_validacao": null,
            "hora_inicio_validacao": null,
            "hora_final_validacao": null,
            evento: obj["id_evento"],
            "tipo_produto": obj["id_tipo_produto"],
            "subtipo_produto": obj["id_subtipo_produto"],
            "codigo_produto": obj["cod_produto"],
          }
        }
        delete data.value["id_evento"]
        delete data.value["id_tipo_produto"]
        delete data.value["id_subtipo_produto"]
        delete data.value["cod_produto"]

        console.log(data.value)

        data.value["regras_produto"] = obj.regras_produto

        tempFile.value = `https://santocartao-files.s3.amazonaws.com/product-pictures/${data.value.codigo_produto}.jpg`

        const eventosRes = await GET(`event`);
        eventosRes.forEach(evento => eventos.value.push({ label: evento.nome + " - " + toDate(evento.data), value: evento.id }));

        const tiposRes = await GET(`tipo_produto`);
        tiposRes.forEach(tipo => tipos.value.push({ label: tipo.nome, value: tipo.id }))

        const subTipoRes = await GET(`subtipo_produto`);
        subTipoRes.forEach(subtipo => subtipos.value.push({ label: subtipo.nome, value: subtipo.id }))


        if (data.value.tipo_produto == 102) {

          const dinheiro = "R$ " + data.value.valor_referencia * 100;
          const x = dinheiro.slice(3, dinheiro.length - 2) + "," + dinheiro.slice(dinheiro.length - 2);
          tempvalor.value = "R$ " + x;

        } else {
          const dinheiro = "R$ " + data.value.valor * 100;

          const x = dinheiro.slice(3, dinheiro.length - 2) + "," + dinheiro.slice(dinheiro.length - 2);

          tempvalor.value = "R$ " + x;
        }

        if (data.value['inicio_validacao'] !== null || data.value['final_validacao'] !== null) {
          data.value['inicio_validacao'] = formatDateEnvio(data.value['inicio_validacao']).replaceAll('-', '/')
          data.value['final_validacao'] = formatDateEnvio(data.value['final_validacao']).replaceAll('-', '/')
        }

        if(data.value['inicio_validacao']){
          data.value['inicio_validacao'] = formatDateEnvio(data.value['inicio_validacao']).replaceAll('-', '/')
        }

        if( data.value['final_validacao']){
          data.value['final_validacao'] = formatDateEnvio(data.value['final_validacao']).replaceAll('-', '/')
        }

        const [diaSelecionado] = dias.value.filter((tipo) => tipo.value == data.value.dia)
        diaSelecionado ? (data.value["dia"] = diaSelecionado.label)
          : (data.value["dia"] = "");

        const [loteSelecionado] = lotes.value.filter((tipo) => tipo.value == data.value['id_lote'])
        loteSelecionado ? (data.value["id_lote"] = loteSelecionado.label)
          : (data.value["id_lote"] = "");

        const [setorSelecionado] = setores.value.filter((tipo) => tipo.value == data.value['setor'])
        setorSelecionado ? (data.value["setor"] = setorSelecionado.label)
          : (data.value["setor"] = "");


        const [pdvSelecionado] = pdv.value.filter((tipo) => tipo.value == data.value.pdv)
        pdvSelecionado ? (data.value["pdv"] = pdvSelecionado.label)
          : (data.value["pdv"] = "");


        const [variacaoSelecionado] = variacoes.value.filter((tipo) => tipo.value == data.value.variacao)
        variacaoSelecionado ? (data.value["variacao"] = variacaoSelecionado.label)
          : (data.value["variacao"] = "");

        const [eventoSelecionado] = eventos.value.filter(evento => evento.value == data.value.evento)

        data.value["evento"] = eventoSelecionado.label

        const [tipoSelecionado] = tipos.value.filter(tipo => tipo.value == data.value.tipo_produto);
        data.value["tipo_produto"] = tipoSelecionado.label

        if (tipoSelecionado.value == 105)
          isFisico.value = true

        const [subTipoSelecionado] = subtipos.value.filter(subtipo => subtipo.value == data.value.subtipo_produto);
        data.value["subtipo_produto"] = subTipoSelecionado.label

        loader.close();


        setTimeout(() => {
          onMouted.value = true
        }, 1000)


      } catch (error) {
        alert.open(
          "Atenção!",
          error.message,
          "warning"
        );
        loader.close();
      }
    });

    function isValidDateTime(dateTimeString) {
        const date = new Date(dateTimeString);
        return !isNaN(date);
      }

    const edit = async () => {

      const obj = ref({});
      obj.value = { ...data.value }

      obj.value = {
        ...data.value,
        "id_evento": data.value.evento,
        "id_tipo_produto": data.value["tipo_produto"],
        "id_subtipo_produto": data.value["subtipo_produto"],
        "cod_produto": data.value["codigo_produto"]
      }

      delete obj.value["tipo_produto"]
      delete obj.value["evento"]
      delete obj.value["subtipo_produto"]
      delete obj.value["codigo_produto"]



      const [eventoSelecionado] = eventos.value.filter(evento => evento.label == data.value.evento);
      eventoSelecionado ? obj.value["id_evento"] = eventoSelecionado.value : obj.value["id_evento"] = "";


      const [tipoSelecionado] = tipos.value.filter(tipo => tipo.label == data.value.tipo_produto);
      tipoSelecionado ? obj.value["id_tipo_produto"] = tipoSelecionado.value : obj.value["id_tipo_produto"] = "";


      const [subTipoSelecionado] = subtipos.value.filter(subtipo => subtipo.label == data.value.subtipo_produto);
      subTipoSelecionado ? obj.value["id_subtipo_produto"] = subTipoSelecionado.value : obj.value["id_subtipo_produto"] = "";

      if (obj.value.id_tipo_produto == 102) {
        if (obj.value.valor_referencia != 0) {
          const valorFloat = tempvalor.value.split(" ", 2);
          valorFloat[1] = valorFloat[1].replace(".", "");
          obj.value["valor_referencia"] = valorFloat[1].replace(",", ".");


        }

      } else {
        const valorFloat = tempvalor.value.split(" ", 2)

        valorFloat[1] = valorFloat[1].replace(".", "")

        obj.value.valor = valorFloat[1].replace(",", ".")
      }

      if (obj.value.valor == ".") {

        obj.value.valor = obj.value.valor.replace(".", '0')
      }

      delete obj.value.id
      delete obj.value.taxa_sc
      delete obj.value.promoter

      if (!obj.value['regras_produto']) {
        delete obj.value['regras_produto']
      }

      const [diaSelecionado] = dias.value.filter((tipo) => tipo.label == data.value.dia)
      diaSelecionado ? (obj.value["dia"] = diaSelecionado.value)
        : (obj.value["dia"] = "");

      const [loteSelecionado] = lotes.value.filter((tipo) => tipo.label == data.value['id_lote'])
      loteSelecionado ? (obj.value["id_lote"] = loteSelecionado.value)
        : (obj.value["id_lote"] = "");

      const [setorSelecionado] = setores.value.filter((tipo) => tipo.label == data.value.setor)
      setorSelecionado ? (obj.value["setor"] = setorSelecionado.value)
        : (obj.value["setor"] = "");


      const [pdvSelecionado] = pdv.value.filter((tipo) => tipo.label == data.value.pdv)
      pdvSelecionado ? (obj.value["pdv"] = pdvSelecionado.value)
        : (obj.value["pdv"] = "");


      const [variacaoSelecionado] = variacoes.value.filter((tipo) => tipo.label == data.value.variacao)
      variacaoSelecionado ? (obj.value["variacao"] = variacaoSelecionado.value)
        : (obj.value["variacao"] = "");

      if (typeof tempFile.value != "string")
        obj.value.file = tempFile.value


      if (obj.value["inicio_validacao"] && obj.value["final_validacao"] && obj.value["hora_inicio_validacao"] && obj.value["hora_final_validacao"]) {

        obj.value["inicio_validacao"] = dateBrtoStamp(obj.value["inicio_validacao"])
        obj.value["final_validacao"] = dateBrtoStamp(obj.value["final_validacao"])

        obj.value["inicio_validacao"]
        obj.value["final_validacao"]
        obj.value["hora_inicio_validacao"]
        obj.value["hora_final_validacao"]

        obj.value["inicio_validacao"] = obj.value["inicio_validacao"] + " " + obj.value["hora_inicio_validacao"]
        obj.value["final_validacao"] = obj.value["final_validacao"] + " " + obj.value["hora_final_validacao"]

        obj.value["inicio_validacao"] = new Date(obj.value["inicio_validacao"])
        obj.value["final_validacao"] = new Date(obj.value["final_validacao"])

        delete obj.value["hora_inicio_validacao"]
        delete obj.value["hora_final_validacao"]
      } else {
        delete obj.value["inicio_validacao"]
        delete obj.value["final_validacao"]
        delete obj.value["hora_inicio_validacao"]
        delete obj.value["hora_final_validacao"]

        delete data.value["inicio_validacao"]
        delete data.value["final_validacao"]
        delete data.value["hora_inicio_validacao"]
        delete data.value["hora_final_validacao"]
      }

      const validate = isEmpty(data.value);

      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open("Atenção", "Deseja realmente editar esse produto?", "warning", "Sim", async () => {

          try {
            const objRequest = new FormData();
            const item = obj.value;
            for (const key in item) {
              objRequest.append(key, item[key]);
            }

            loader.open();
            await PUT(`produto/${route.params.id}`, objRequest);
            alert.open(
              "Sucesso!",
              `Produto editado com sucesso!`,
              "success"
            );
            loader.close();
            router.push(`/produtos/${obj.value["id_evento"]}`);
          } catch (e) {
            loader.close();
            alert.open("Atenção!", e.message, "warning");
          }
        },
          modal.close())
      }
    };

    const changeImage = (event) => {
      const obj = {
        "evento": data.value.evento,
        "tipo_produto": data.value.tipo_produto,
        "subtipo_produto": data.value.subtipo_produto,
        "codigo_produto": data.value["codigo_produto"],
        "sku_magento": data.value["sku_magento"],
        lote: data.value.lote,
        sexo: data.value.sexo,
        detalhes: data.value.detalhes,
        valor: data.value.valor,
        "qnt_por_produto": data.value["qnt_por_produto"],
        "tx_conveniencia": data.value["tx_conveniencia"],
        promoter: data.value.promoter,
        pdv: data.value["pdv"],
        dia: data.value["dia"],
        setor: data.value["setor"],
        "id_lote": data.value["id_lote"],
        variacao: data.value["variacao"],
        "regras_produto": data.value['regras_produto'],
        "inicio_validacao": data.value['inicio_validacao'],
        "final_validacao": data.value['final_validacao']
      }
      data.value = obj;
      tempFile.value = event.target.files[0]
    }

    const readURL = file => {
      if (typeof (file) == 'string') {

        return file;

      }
      return window.URL.createObjectURL(file)
    };

    watch(() => response.value, () => {
      if (response.value.status == 404)
        alert.open("Atenção!", "Esse produto não possui imagem", "warnning")
    })

    const funcao = () => {

      const [eventoSelecionado] = eventos.value.filter(evento => evento.label == data.value.evento);
      const getNomeEvento = eventoSelecionado.label.split(" ")


      const obj = {
        file: IngressoFile.value,
        evento: getNomeEvento[0],
        "id_evento": eventoSelecionado.value,
        "codigo_produto": data.value.codigo_produto
      }


      modal.open("Envio de Ingressos", "Deseja realmente adicionar esses Ingressos Fisicos ?", "warning", "Sim", async () => {

        const validate = isEmpty(obj)

        if (validate) {
          alert.open("Atenção!", validate, "warning");
        } else {
          loader.open();
          const objRequest = new FormData();
          const item = obj;
          for (const key in item) {
            objRequest.append(key, item[key]);
          }
          try {
            await POST("produto/ingresso", objRequest);
            alert.open("Sucesso!", `Ingresso Enviado com Sucesso!`, "success");
            loader.close();
            router.push("/produtos");

          } catch (error) {
            loader.close();
            alert.open("Atenção!", "Um erro aconteceu, tente novamente", "warning");
            obj.file = null;
          }
        }
      })
    }

    const addFile = (event) => {
      IngressoFile.value = event.target.files[0]
    }




    const changeSubTipo = async (id) => {
      if (id == 0) {
        subtipos.value.length = 0;
        data.value["subtipo_produto"] = ""
      }
      subtiposRes.value.forEach((subtipo) => {
        if (subtipo.id_tipo_produto == id)
          subtipos.value.push({ label: subtipo.nome, value: subtipo.id });
      });

      if (id == 102) {
        if (data.value.detalhes.substring(0, 11) != "Promoter - ")
          data.value.detalhes = "Promoter - " + data.value.detalhes
      } else {
        //remover Promoter caso mude...
        if (data.value.detalhes.substring(0, 11) == "Promoter - ") {
          const str = data.value.detalhes.split(" ")
          data.value.detalhes = str[2]
        }
      }

      if (subtipos.value.length == 0 && id != 0) {
        alert.open(
          "Atenção!",
          "Não existe subtipos para esse Tipo de produto",
          "warning"
        );
      }
    };



    watch(() => data.value.tipo_produto, (value) => {

      if (onMouted.value) {
        subtipos.value = []

        data.value['subtipo_produto'] = ''


        const [tipoSelecionado] = tipos.value.filter(
          (tipo) => tipo.label == data.value.tipo_produto
        );
        if (tipoSelecionado == undefined) {
          changeSubTipo(0);
        } else {
          changeSubTipo(tipoSelecionado.value);
        }

      }


    });




    return {
      edit,
      data,
      changeImage,
      readURL,
      eventos,
      tipos,
      subtipos,
      tempvalor,
      tempFile,
      funcao,
      addFile,
      IngressoFile,
      isFisico, dias,
      pdv,
      lotes,
      variacoes,
      setores,
      changeSubTipo
    };
  },
};

</script>

<style>
.bton {
  z-index: 5;
  width: 140px;
  height: 75px;
}

input[type="file"]::-webkit-file-upload-button {
  height: 40px;
}
</style>